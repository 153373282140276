import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Menu from "./js/Menu";
import Headroom from "headroom.js";

import lazySizes from "lazysizes";

import "./app.scss";

// barba.init({
//   debug: true,
//   logLevel: "error",
//   timeout: 5000,
// });

runActions();

function runActions() {
  console.log("runActions");

  // action("Slider", Slider);
  document
    .querySelectorAll('[data-action="Slider"]')
    .forEach((el) => Slider(el));

  Menu(document.querySelectorAll(".menu-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const modelBtn = document.querySelectorAll(".model-btn");
  const modelClose = document.querySelectorAll(".model-close");

  if (modelBtn) {
    modelBtn.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        // menu?.classList?.toggle("hidden");
        document.body.classList.add("overflow-hidden");
        const modelId = btn.dataset.target;
        const model = document.getElementById(modelId);
        model.classList.toggle("hidden");
      })
    );
  }

  if (modelClose) {
    modelClose.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".model"));
        e.target.closest(".model").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }
}

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {});
